<template>
  <v-container fluid>
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-card>
          <v-card-actions>
            <v-icon @click="close">mdi-arrow-left-circle</v-icon>

            <v-spacer></v-spacer>
            <v-btn color="primary" @click="addFeedback">Add Feedback</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-data-table
              id="list-table"
              ref="datatable"
              :headers="currentHeaders"
              :items="feedbacks"
              item-key="index"
              class="mr-2"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td>{{ props.item.typeOfFeedback }}</td>
                  <td>{{ props.item.date }}</td>
                  <td>{{ props.item.personProvidingFeedback}}</td>
                  <td>{{ props.item.personName}}</td>
                  <td>{{ props.item.respondersName}}</td>
                  <td>{{ props.item.typeOfResponse}}</td>
                  <td>{{ props.item.dateDate }}</td>

                  <td class="justify-center layout px-0">
                    <v-icon small class="mr-2" @click="editItem(props.item)">mdi-pencil</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as feedbacksService from "../../services/feedbackService";

import moment from "moment";
Vue.use(VeeValidate);

export default {
  name: "feedback",

  data: () => ({
    editedIndex: -1,
    vm: {},

    dialog: false,
    feedbacks: [],
    expiredFeedbacks: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    currentHeaders: [
      { text: "Type", value: "type" },
      { text: "Date/time", value: "date" },
      { text: "Person Providing Feeback ", value: "personProvidingFeedback" },
      {text: "Person Name ", value: "personName" },
      { text: "Responder", value: "respondersName" },
      { text: "Type of response", value: "typeOfResponse" },
      { text: "Date closed", value: "dateDate" },
      { text: "", value: "actions" }
    ]
  }),
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    }
  },
  methods: {
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },
    init() {
      this.vm = {};
      const me = this;
      feedbacksService.list(this.currentOrganisation.id).then(result => {
        let feedbacks = [];
        result.docs.map(doc => {
          const feedback = doc.data();
          feedback.id = doc.id;
         // feedback.date = feedback.date.toDate();
          //feedback.dateDate = feedback.dateDate.toDate();
          feedbacks.push(feedback);
        });
        console.log(feedbacks);
        me.feedbacks = feedbacks;
      });
    },
    editItem(item) {
      this.$store.commit("setCurrentFeedback", item);
      this.$router.push("/feedback/feedback-details");
    },
    close() {
      this.$router.push("/feedback");
      setTimeout(() => {
        this.vm = {};
      }, 300);
    },
    addFeedback() {
      this.$store.commit("setCurrentFeedback", null);
      this.$router.push("/feedback/feedback-details");
    },
    feedbackAdded(data) {
      if (this.editedIndex > -1) {
        Object.assign(this.feedbacks[this.editedIndex], data);
      } else {
        if (moment(data.expiry).isBefore(new Date())) {
          this.expiredFeedbacks.push(data);
        } else {
          this.feedbacks.push(data);
        }
      }
      this.feedbackClosed();
      console.log(data);
    },
    feedbackClosed() {
      this.dialog = false;
      setTimeout(() => {
        this.vm = {};
        this.editedIndex = -1;
        this.$forceUpdate();
      }, 300);
    }
  }
};
</script>
